export const Services_Data = [
  {
    name: "Web X",
    sdesc: "Custom websites tailored to your needs.",
    desc: "Our Web X service offers custom website development to meet your specific requirements. Whether you need a corporate site, an event page, or an e-commerce platform, our expert team ensures a seamless, user-friendly experience. We specialize in responsive design, SEO optimization, and ongoing support to elevate your online presence. With Web X, your website becomes a powerful tool for engagement and success.",
    details: {
      features: [
        "Responsive design for all devices",
        "SEO-optimized structures",
        "Tailored functionality to fit your business",
        "Post-launch support and maintenance",
      ],
      pricing: "Starting at $500, customizable based on features",
      deliveryTime: "Typically delivered within 2-4 weeks",
    },
    comparisonChart: {
      competitors: [
        { name: "Web X" },
        { name: "Competitor B" },
        { name: "Competitor C" },
      ],
      features: [
        {
          name: "Responsive Design",
          values: ["✔", "✔", "✘"],
        },
        {
          name: "SEO Optimization",
          values: ["✔", "✘", "✘"],
        },
        {
          name: "Custom Functionality",
          values: ["✔", "✘", "✔"],
        },
        {
          name: "Post-launch Support",
          values: ["✔", "✔", "✘"],
        },
      ],
    },
    downloadables: [
      { name: "Brochure", file: "web_x_brochure.pdf" },
      { name: "CaseStudies", file: "web_x_case_study.pdf" },
    ],
    testimonials: [
      {
        clientName: "Client A",
        feedback:
          "Our online sales increased by 40% thanks to the seamless user experience.",
        rating: 4,
        company: "Company A",
      },
      {
        clientName: "Client B",
        feedback:
          "The custom features made our website stand out and meet our business needs.",
        rating: 3,
        company: "Company A",
      },
      {
        clientName: "Client C",
        feedback:
          "The responsive design and SEO optimization drastically improved our search rankings.",
        rating: 2,
        company: "Company A",
      },
    ],
    faq: [
      {
        question: "How long does it take to build a custom website?",
        answer:
          "It typically takes 2-4 weeks, depending on the complexity and scope of the project.",
      },
      {
        question: "Do you provide post-launch support?",
        answer:
          "Yes, we offer ongoing support and maintenance to ensure your website continues to perform optimally.",
      },
      {
        question: "Do you offer e-commerce website development?",
        answer:
          "Yes, we specialize in e-commerce platforms that are fully customizable and secure.",
      },
      {
        question: "Can I update my website after it is launched?",
        answer:
          "Yes, we provide training and support to help you manage and update your website as needed.",
      },
    ],
    cta: {
      text: "Get Your Custom Website Today!",
      link: "/contact",
    },
  },
  {
    name: "Event X",
    sdesc: "Revolutionize event management with automation.",
    desc: "Event X is an advanced event registration and management system, integrating barcode and facial recognition technologies for seamless operations. Our service includes an online registration website and onsite ID cards or bands with custom barcodes. These features facilitate effortless check-ins, food and beverage payments, and service access, ensuring an elevated event experience. Let Event X take your event management to the next level.",
    details: {
      features: [
        "Online and onsite registration",
        "Custom barcode-enabled ID cards or bands",
        "Facial recognition for efficient check-ins",
        "Integrated payment systems for convenience",
      ],
      pricing: "Custom quotes available based on event scale",
      deliveryTime: "2-3 weeks for setup and implementation",
    },
    comparisonChart: {
      competitors: [
        { name: "Event X" },
        { name: "Competitor B" },
        { name: "Competitor C" },
      ],
      features: [
        {
          name: "Barcode ID Cards",
          values: ["✔", "✘", "✔"],
        },
        {
          name: "Facial Recognition",
          values: ["✔", "✔", "✘"],
        },
        {
          name: "Integrated Payments",
          values: ["✔", "✘", "✔"],
        },
        {
          name: "Real-time Updates",
          values: ["✔", "✘", "✘"],
        },
      ],
    },
    downloadables: [
      { name: "Brochure", file: "event_x_brochure.pdf" },
      { name: "CaseStudies", file: "event_x_case_study.pdf" },
    ],
    testimonials: [
      {
        clientName: "Client A",
        feedback:
          "The automated check-in system saved us time and improved attendee satisfaction.",
        rating: 5,
        company: "Company A",
      },
      {
        clientName: "Client B",
        feedback:
          "The barcode ID bands were a hit, making access to services much smoother.",
        rating: 5,
        company: "Company A",
      },
      {
        clientName: "Client C",
        feedback:
          "The facial recognition technology enhanced security and reduced wait times during check-ins.",
        rating: 5,
        company: "Company A",
      },
    ],
    faq: [
      {
        question: "How does the facial recognition work?",
        answer:
          "It uses real-time analysis of facial features to identify attendees, reducing wait times and increasing accuracy.",
      },
      {
        question: "Can I customize the registration process?",
        answer:
          "Yes, we tailor the registration process to meet your event's specific needs.",
      },
      {
        question: "Is there a limit to the number of attendees?",
        answer:
          "No, our system is scalable to handle events of all sizes, from small gatherings to large conferences.",
      },
      {
        question: "How secure is the facial recognition data?",
        answer:
          "We use advanced encryption methods to ensure that all facial recognition data is securely stored and processed.",
      },
    ],
    cta: {
      text: "Book Event X for Your Next Event!",
      link: "/contact",
    },
  },
  {
    name: "Face X",
    sdesc: "Real-time facial recognition for attendance.",
    desc: "Face X transforms traditional attendance systems with state-of-the-art facial recognition technology. It allows real-time recognition and simultaneous attendance marking for multiple individuals. This efficient and seamless solution is designed to enhance accuracy and convenience, making attendance management hassle-free and reliable. Experience the future of attendance systems with Face X.",
    details: {
      features: [
        "Simultaneous recognition of multiple faces",
        "Real-time attendance updates",
        "Highly accurate and secure",
        "Easily integrates with existing systems",
      ],
      pricing: "Starts at $200 per system",
      deliveryTime: "Setup completed in 1-2 weeks",
    },
    comparisonChart: {
      competitors: [
        { name: "Face X" },
        { name: "Competitor B" },
        { name: "Competitor C" },
      ],
      features: [
        {
          name: "Real-time Updates",
          values: ["✔", "✘", "✔"],
        },
        {
          name: "Simultaneous Recognition",
          values: ["✔", "✔", "✘"],
        },
        {
          name: "Integration Support",
          values: ["✔", "✘", "✔"],
        },
        {
          name: "Pricing Flexibility",
          values: ["✔", "✘", "✘"],
        },
      ],
    },
    downloadables: [
      { name: "Brochure", file: "face_x_brochure.pdf" },
      { name: "CaseStudies", file: "face_x_case_study.pdf" },
    ],
    testimonials: [
      {
        clientName: "Client A",
        feedback:
          "This system has streamlined our attendance process and boosted efficiency.",
        rating: 5,
        company: "Company A",
      },
      {
        clientName: "Client B",
        feedback:
          "The facial recognition technology has been incredibly reliable and accurate.",
        rating: 5,
        company: "Company A",
      },
      {
        clientName: "Client C",
        feedback:
          "Face X made managing attendance so much easier, even for large events with thousands of attendees.",
        rating: 5,
        company: "Company A",
      },
    ],
    faq: [
      {
        question: "How many faces can the system recognize at once?",
        answer:
          "Face X can simultaneously recognize up to 50 faces in real-time.",
      },
      {
        question: "Is the system secure?",
        answer:
          "Yes, Face X uses advanced encryption and data protection protocols to ensure user privacy.",
      },
      {
        question: "What is the setup time for Face X?",
        answer:
          "Setup time is typically 1-2 weeks, depending on the scale of your implementation.",
      },
      {
        question: "Does Face X work in low-light environments?",
        answer:
          "Yes, Face X uses advanced algorithms that can detect faces even in low-light conditions.",
      },
    ],
    cta: {
      text: "Implement Face X for Hassle-Free Attendance!",
      link: "/contact",
    },
  },
  {
    name: "Open X",
    sdesc: "Free tools for the community.",
    desc: "Open X is a hub of free, accessible tools and services designed to empower the community. From QR code and barcode generators to file converters and image compressors, Open X provides practical solutions typically available at a cost elsewhere. Ideal for individuals, small businesses, and students, Open X ensures accessibility without hidden charges, fostering empowerment through technology.",
    details: {
      features: [
        "Free tools for everyone",
        "No sign-ups required",
        "Simple, easy-to-use interface",
        "Community-driven platform",
      ],
      pricing: "Free",
      deliveryTime: "Instant access",
    },
    comparisonChart: {
      competitors: [
        { name: "Open X" },
        { name: "Competitor B" },
        { name: "Competitor C" },
      ],
      features: [
        {
          name: "Free Access",
          values: ["✔", "✘", "✔"],
        },
        {
          name: "No Sign-ups Required",
          values: ["✔", "✘", "✔"],
        },
        {
          name: "Wide Tool Selection",
          values: ["✔", "✔", "✘"],
        },
        {
          name: "Fast Results",
          values: ["✔", "✘", "✔"],
        },
      ],
    },
    downloadables: [
      { name: "Brochure", file: "open_x_brochure.pdf" },
      { name: "CaseStudies", file: "open_x_case_study.pdf" },
    ],
    testimonials: [
      {
        clientName: "Client A",

        feedback:
          "Open X has saved me so much time with its fast file compression and conversion tools.",
        rating: 5,
        company: "Company A",
      },
      {
        clientName: "Client B",
        feedback:
          "The variety of free tools provided is amazing – it’s like having a toolkit in my pocket.",
        rating: 5,
        company: "Company A",
      },
      {
        clientName: "Client C",
        feedback:
          "I’ve saved so much time using Open X for my file conversion and compression needs.",
        rating: 5,
        company: "Company A",
      },
    ],
    faq: [
      {
        question: "How does Open X stay free?",
        answer:
          "We believe in democratizing access to technology and keeping tools free for all users.",
      },
      {
        question: "Do I need to create an account to use Open X?",
        answer:
          "No, all tools are available immediately without the need for an account.",
      },
      {
        question: "Can I suggest a new tool for Open X?",
        answer:
          "Yes, we welcome suggestions for new tools. Feel free to contact us with your ideas.",
      },
      {
        question: "Is there any limit to how many tools I can use?",
        answer:
          "No, you can use as many tools as you need, completely free of charge.",
      },
    ],
    cta: {
      text: "Start Using Open X Now!",
      link: "/open-x",
    },
  },
];
