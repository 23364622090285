import React, { useEffect, useState } from "react";
import "./Services.css";
import { Services_Data } from "./servicesData";
import { Link } from "react-router-dom";

const Services = (args) => {
  return (
    <div className="home-cards-row-container" id={args.id}>
      <h1 className="home-cards-row-title" id="title">
        Our Services
      </h1>
      <div className="home-cards-row">
        {Services_Data.map((card, index) => (
          <div className="home-vertical-card">
            <div className="home-vertical-card-bottom">
              <h1 className="home-vertical-name-text">{card.name}</h1>
              <p>{card.desc}</p>
            </div>
            <div className="home-card-button-container">
              <Link
                to={`/services/${card.name.toLowerCase().replace(/\s/g, "_")}`}
              >
                <button className="home-card-button" type="button">
                  Learn More
                </button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
