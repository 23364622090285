import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./Services-Page.css";
import "react-multi-carousel/lib/styles.css";
import { Services_Data } from "./servicesData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons";
const Event_Page = () => {
  const { serviceName } = useParams();
  const events = Services_Data;

  const eventData = events.find(
    (event) =>
      event.name?.toLowerCase() ===
      serviceName?.toLowerCase()?.replace(/_/g, " ")
  );

  if (!eventData) {
    return <div>Service not found!</div>; // Handle case where service is not found
  }

  return (
    <div className="service-page-main">
      {/* Service Title and Short Description */}
      <h1 className="home-cards-row-title">
        {eventData.name}
        <p className="service-sdesc">{eventData.sdesc}</p>
      </h1>

      {/* Full Description */}
      {eventData.desc && (
        <div className="service-page-row">
          <div className="service-page-row-container">
            {eventData.desc.split("\\n").map((line, index) => (
              <React.Fragment key={index}>
                {line === "" ? <br /> : <p>{line}</p>}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}

      {/* Features, Pricing, and Delivery Time */}
      {eventData.details && (
        <div className="service-page-row">
          <div className="service-page-row-container">
            <h2 className="home-cards-row-header">Features</h2>
            <div className="service-page-cards-row">
              {eventData.details.features.map((card, index) => (
                <div className="service-page-vertical-card">
                  <div className="service-page-vertical-card-bottom">
                    <h1 className="service-page-vertical-name-text">{card}</h1>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="service-page-row-container" id="service-details">
            <div className="service-page-item">
              <h2 className="home-cards-row-header">Pricing</h2>
              <p>{eventData.details.pricing}</p>
              {/* <div className="service-page-cards-row">
                {eventData.details.features.map((card, index) => (
                  <div className="service-page-vertical-card">
                    <div className="service-page-vertical-card-bottom">
                      <h1 className="service-page-vertical-name-text">
                        {card}
                      </h1>
                    </div>
                  </div>
                ))}
              </div> */}
            </div>
            <div className="service-page-item">
              <h2 className="home-cards-row-header">Delivery Time</h2>
              <p>{eventData.details.deliveryTime}</p>
            </div>
          </div>
        </div>
      )}

      {/* Comparison Chart */}
      {eventData.comparisonChart && (
        <div className="service-page-row">
          <div className="service-page-row-container">
            <h2 className="home-cards-row-header">Comparison Chart</h2>
            <table className="service-page-comparison-table">
              <thead>
                <tr>
                  <th>Feature</th>
                  {eventData.comparisonChart.competitors.map(
                    (competitor, index) => (
                      <th key={index}>{competitor.name}</th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {eventData.comparisonChart.features.map((feature, index) => (
                  <tr key={index}>
                    <td>{feature.name}</td>
                    {feature.values.map((value, idx) => (
                      <td key={idx}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Client Testimonials */}
      {eventData.testimonials && (
        <div className="service-page-row">
          <div className="service-page-row-container">
            <h2 className="home-cards-row-header">What Our Clients Say</h2>
            <div className="service-page-testimonial-cards-row">
              {eventData.testimonials.map((testimonial, index) => (
                <div className="service-page-testimonial-card">
                  <div className="service-page-testi-card-rating-container">
                    <div className="service-page-testi-card-rating">
                      {Array.from({ length: testimonial.rating }).map(
                        (_, index) => (
                          <FontAwesomeIcon key={index} icon={faStar} />
                        )
                      )}
                      {Array.from({ length: 5 - testimonial.rating }).map(
                        (_, index) => (
                          <FontAwesomeIcon key={index} icon={farStar} />
                        )
                      )}
                    </div>
                    <p className="service-page-card-rating-text">
                      {testimonial.rating === 5
                        ? "Excellent Service"
                        : testimonial.rating === 4
                        ? "Great Service"
                        : testimonial.rating === 3
                        ? "Good Service"
                        : testimonial.rating === 2
                        ? "Fair Service"
                        : "Bad Service"}
                    </p>
                  </div>

                  <p key={index}>"{testimonial.feedback}"</p>
                  <div className="service-page-testi-card-cite">
                    <cite>-{testimonial.clientName}</cite>
                    {testimonial.company && <cite>{testimonial.company}</cite>}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* FAQs */}
      {eventData.faq && (
        <div className="service-page-row">
          <div className="service-page-row-container">
            <h2 className="home-cards-row-header">
              Frequently Asked Questions
            </h2>
            <div className="service-page-faq-card-container">
              {eventData.faq.map((item, index) => (
                <div className="service-page-faq-card">
                  <h2>{item.question}</h2>
                  <cite>{item.answer}</cite>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Downloadable Content */}
      {eventData.downloadables && (
        <div className="service-page-row">
          <div className="service-page-row-container">
            <h2 className="home-cards-row-header">Downloadable Resources</h2>
            <div className="service-page-download-btn-container">
              {eventData.downloadables.map((download, index) => (
                <a
                  href={`${serviceName}/${download.file}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button class="service-page-download-button" type="button">
                    <span class="service-page-download-button__text">
                      {download.name}
                    </span>
                    <span class="service-page-download-button__icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 35 35"
                        id="bdd05811-e15d-428c-bb53-8661459f9307"
                        data-name="Layer 2"
                        class="svg"
                      >
                        <path d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z"></path>
                        <path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z"></path>
                        <path d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z"></path>
                      </svg>
                    </span>
                  </button>
                </a>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Call to Action */}
      {/* {eventData.cta && (
        <div className="service-page-row">
          <div className="service-page-row-container">
            <h2 className="home-cards-row-header">
              Get Started with {eventData.name}
            </h2>
            <p>{eventData.cta.text}</p>
            <a
              href={eventData.cta.link}
              className="cta-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              {eventData.cta.buttonText}
            </a>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Event_Page;
